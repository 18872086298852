import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";

export type UserAuth = {
  id: string;
  firstName?: string;
  publicName: string;
  email: string;
  emailVerified: boolean;
  createdAt: string;
  acceptedTermsAt: string;
  newsletter: boolean;
};

async function fetcher(): Promise<UserAuth | null> {
  const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/auth`, {
    credentials: "include",
  });
  if (res.status !== 200) {
    return null;
  }
  const result = (await res.json()) as UserAuth;
  return result;
}

export type UseUserType = {
  data: UserAuth | null | undefined;
  isError: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  availableLoginMethods: Array<"password" | "passwordless">;
  /**
   * This is used to determine the type/status of account creation
   * undefined: no request has been made yet
   * "new": a new account has been created
   * "existing": the account already exists
   * "non-existing": the account does not exist and wasn't created automatically
   */
  accountCreationType: "new" | "existing" | "non-existing" | undefined;
  login: (
    values:
      | { email: string; password: string }
      | { email: string; code: string },
  ) => Promise<Response>;
  signUp: (values: {
    email: string;
    newPassword: string;
    firstName: string;
    lastName: string;
    timezone: string;
    recaptchaToken: string;
  }) => Promise<Response>;
  requestAuthCode: (values: {
    firstName?: string;
    lastName?: string;
    email: string;
    newsletter?: boolean;
    timezone: string;
    recaptchaToken: string;
    dry?: boolean; // this is used to check if the email is already registered
  }) => Promise<Response>;
  logout: () => Promise<Response>;
  resetLoginInfo: () => void;
};

export function useUser({
  redirectTo = "",
  redirectIfFound = false,
  returnAfterAuthentication = true,
}: {
  /**
   *  If redirectTo is set, redirect if the user is not authenticated
   */
  redirectTo?: string;
  /**
   * If redirectIfFound is true, redirect if the user is already authenticated.
   * This is useful if you want to redirect users from the login page if they are
   * already authenticated. Defaults to false.
   */
  redirectIfFound?: boolean;
  /**
   * If returnAfterAuthentication is set to true, the user will be redirected to the
   * page they were trying to access before authentication. Otherwise, they will be
   * redirected to the default page (usually /home). Defaults to true.
   */
  returnAfterAuthentication?: boolean;
} = {}): UseUserType {
  const router = useRouter();
  const [availableLoginMethods, setAvailableLoginMethods] = useState<
    UseUserType["availableLoginMethods"]
  >([]);
  const [accountCreationType, setAccountCreationType] =
    useState<UseUserType["accountCreationType"]>();
  const { data, error, mutate } = useSWR<UserAuth | null>("user", fetcher, {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  });

  const resetLoginInfo = useCallback(() => {
    setAvailableLoginMethods([]);
    setAccountCreationType(undefined);
  }, []);

  const login = useCallback<UseUserType["login"]>(
    async (values) => {
      const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
        }),
        credentials: "include",
      });

      if (response.ok) {
        const body = await response.json();
        mutate(body, false);
      }
      return response;
    },
    [mutate],
  );

  const signUp = useCallback<UseUserType["signUp"]>(
    async (values) => {
      const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
        }),
        credentials: "include",
      });

      if (response.ok) {
        const body = await response.json();
        mutate(body, false);
      }
      return response;
    },
    [mutate],
  );

  const requestAuthCode = useCallback<UseUserType["requestAuthCode"]>(
    async ({ dry, ...values }) => {
      const qs = new URLSearchParams({ dry: String(dry) });
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/users?${qs}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...values,
            // this will be sent back to the frontend in the login code email magic link
            redirectTo,
            // the callId is used to track the referral and mention the call title in the welcome email
            ...(router.query.callId
              ? { referral: { call: router.query.callId } }
              : {}),
          }),
          credentials: "include",
        },
      );

      if (response.ok) {
        const body = await response.json();
        setAvailableLoginMethods(body.availableLoginMethods);

        if (body.status.match(/not exist/i)) {
          setAccountCreationType("non-existing");
        }

        if (body.status.match(/exists/i)) {
          setAccountCreationType("existing");
        }

        if (body.status.match(/created/i)) {
          setAccountCreationType("new");
        }
      }
      return response;
    },
    [redirectTo, router.query.callId],
  );

  const logout = useCallback<UseUserType["logout"]>(async () => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/auth`, {
      credentials: "include",
      method: "DELETE",
    });
    if (response.ok) {
      mutate(null, false);
    }
    return response;
  }, [mutate]);

  const isAuthenticated = !!data;
  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || data === undefined) return;

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && data === null) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && isAuthenticated)
    ) {
      // This redirect is usually used to redirect the user to the login or signup page.
      // After authentication, the user is redirected back to the page they were trying
      // to access if returnAfterAuthentication is set to true (which is the default).
      // If returnAfterAuthentication is set to false, the user will be redirected to
      // the default page (usually /home in our main app).

      // Return redirects work differently depending on whether the redirectTo URL
      // is on the same host as the current page or a different host.
      const currentHost = window.location.host;
      // Check if redirectTo is a full URL with hostname
      const redirectToIsFullUrl = redirectTo.match(/^https?:\/\//);
      // If redirectTo is a full URL, we have to check if the hostname matches
      // the current host
      const redirectToIsSameHost =
        redirectToIsFullUrl && new URL(redirectTo).host === currentHost;

      // It can be a security risk to redirect to just any random URL, so we only allow
      // redirects to relative URLs or to a host in the allowed domains list
      const checkIsSafeToRedirect = () => {
        // There is no security risk if no redirect is needed
        if (!redirectTo) {
          return true;
        }

        const isRelativeUrl = redirectTo.startsWith("/");

        // If it's a relative URL, it's safe to redirect
        if (isRelativeUrl) {
          return true;
        }

        // If redirectTo is not a relative url, it must have an allowed domain

        // Get the hostname of the redirectTo URL
        const redirectToHost = new URL(redirectTo).hostname;
        // Get redirectTo second-level domain and top-level domain
        // e.g. "hello.example.com" -> "example.com"
        // We do this to allow redirects to subdomains of the allowed domains as well
        const redirectToSecondLevelDomain = redirectToHost
          .split(".")
          .slice(-2)
          .join(".");

        const allowedDomains =
          process.env.NEXT_PUBLIC_ALLOWED_DOMAINS?.split(",") || [];

        return allowedDomains.some(
          (allowedDomain) => allowedDomain === redirectToSecondLevelDomain,
        );
      };

      // If redirectTo is not safe, we bail out and log a warning
      if (!checkIsSafeToRedirect()) {
        console.warn(
          `The provided redirectTo URL (${redirectTo}) does not have an allowed domain name.`,
        );
        return;
      }

      // If redirectTo is on a different host, we have to include the full URL
      // as returnRedirect, so the return after authentication lands on the
      // correct page. Otherwise, we can just use the path name (in-same-app navigation)
      const returnRedirect =
        redirectToIsFullUrl && !redirectToIsSameHost
          ? // Encoding happens in the URLSearchParams
            window.location.href
          : router.asPath;

      // Since redirectTo could contain query params, we have to merge them
      // with the possible return-redirectTo query param to avoid possibly invalid
      // query params in the URL with (multiple "?", e.g. ?redirectTo=/home?foo=bar)
      const [redirectToUrl, redirectToSearchParams] = redirectTo.split("?");
      // Collect query params from redirectTo
      const newSearchParams = new URLSearchParams(redirectToSearchParams);

      if (returnAfterAuthentication) {
        newSearchParams.set("redirectTo", returnRedirect);
      }

      router.push(
        `${redirectToUrl}${
          newSearchParams.size > 0 ? `?${newSearchParams.toString()}` : ""
        }`,
      );
    }
  }, [
    data,
    redirectIfFound,
    redirectTo,
    isAuthenticated,
    router,
    returnAfterAuthentication,
  ]);

  return {
    isError: !!error,
    isLoading: !error && data === undefined,
    isAuthenticated,
    availableLoginMethods,
    accountCreationType,
    data,
    login,
    signUp,
    requestAuthCode,
    logout,
    resetLoginInfo,
  };
}
